(function() {
  function goToReport(clientId, reportId) {
    window.location.href = "/clients/" + clientId + "/reports/" + reportId;
  }

  const ScatterChartTimeBuilder = {};

  ScatterChartTimeBuilder.build = function(elem, data, domain) {
    // console.log(data);
    const margin = { top: 15, bottom: 40 };

    const width = $(elem).width();
    const height = $(elem).height() - margin.top - margin.bottom;

    const x = d3.scale.ordinal()
      .domain(domain)
      .rangePoints([0, width - 90]);

    // y-axis domain and range
    // const y = d3.scale.linear()
    //   .domain([0,100])
    //   .range([ height - 20, 0 ]);

    const y = d3.scale.linear()
      .domain([0, 110])
      .range([height - 10, 0]);

    const xAxis = d3.svg.axis()
      .scale(x)
      .orient("bottom");

    // draw the y axis
    const yAxis = d3.svg.axis()
      .scale(y)
      .ticks(5)
      .tickSize(-(width - 50), 0)
      .orient("left");

    const svg = d3.select(elem).append("svg")
      .attr("width", width)
      .attr("height", "100%");

    // define the div for the tooltip
    const tooltip = d3.select(elem).append("div")
      .attr("class", "tooltip")
      .style("z-index", 2000)
      .style("opacity", 0);

    // .attr('transform', 'translate(20,0)')
    svg.append("g")
      .attr("transform", "translate(20,10)")
      .attr("class", "y-axis")
      .call(yAxis);

    // .attr("transform", "translate(40," + (height + 10) + ")")
    svg.append("g")
      .attr("class", "x axis")
      .attr("transform", "translate(40," + (height + 15) + ")")
      .call(xAxis)
      .selectAll(".tick text")
      .style("text-anchor", "start")
      .attr("x", 6)
      .attr("y", 6);

    // y-axis text positioning
    d3.select(elem + " .y-axis").selectAll(".tick text").style("transform", "translate(15px, 0px)");

    const g = svg.append("svg:g");

    // .attr("cy", function (d) { return y(d.y); } )
    // add circles
    g.selectAll("scatter-dots")
      .data(data)
      .enter().append("svg:circle")
      .attr("cx", function(d) { return x(d.date) + 57; })
      .attr("cy", function(d) { return y(d.y) + 5; })
      .attr("r", 5)
      .attr("stroke-width", "2px")
      .attr("stroke", "#4990E2")
      .attr("fill", "transparent")
      .attr("cursor", "pointer")
      .on("mouseover", function(d) {
        // add tooltips
        tooltip.transition()
          .duration(300)
          .style("opacity", 1);

        const color = d.color;

        // if(d.y > 80) {
        //   color = "green";
        // } else if(d.x < 50) {
        //   color="red";
        // } else {
        //   color="yellow";
        // }

        tooltip.html("<h4> " + d.title + " </h4> <div class='square'><p class=" + color + "> " + d.y + " </p> </div><p> CCI Score</p> ")
          .style("left", ((d3.event.offsetX) + 30) + "px")
          .style("top", ((d3.event.offsetY) - 50) + "px");
      })
      .on("click", function(d) {
        goToReport(d.client_id, d.report_id);
      })
      .on("mouseout", function(d) {
        tooltip.transition()
          .duration(300)
          .style("opacity", 0);
      });
  };

  window.ChartBuilders = (window.ChartBuilders || {});
  window.ChartBuilders.ScatterChartTimeBuilder = ScatterChartTimeBuilder;
})();
